.dropdown-menu {
    background: #fff;
    width: 200px;
    margin-left: -18px;
    position: absolute;
    top: 70px;
    list-style: none;
    text-align: center;
    border: 3px solid  #DB4F33;
}

.dropdown-menu li {
    height: 50px;
    /* background: #1888ff;
    cursor: pointer; */
}

.dropdown-menu li:hover {
    /* background: #5cabff; */
    background: #DB4F33;
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #DB4F33;
    padding: 16px;
}
.dropdown-link:hover {
    color: #fff;
}

@media screen and (max-width: 960px) {
    .fa-caret-down {
    display: none;
    }
}