.fp-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #f8f8f8ad;
}
.fp-container .fp-loader {
    top: 30%;
    left: 42%;
    z-index: 1000;
    width: auto;
    height: auto;
    position: absolute;
}


@media screen and (max-width: 768px) {
    .fp-container .fp-loader {
        width: 70px;
        height: 70px;
    }
}